<template>
  <div class="fsc">
    <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">金融服务中心</div>
    <div class="fsc-content">
      <div @click="navTo('https://www.zhongdengwang.org.cn/')"><img src="../assets/img/jr1.png" alt=""></div>
      <div @click="popTo(2)"><img src="../assets/img/jr2.png" alt=""></div>
      <div @click="navTo('https://yunshanfu.unionpay.com/')"><img src="../assets/img/jr3.png" alt=""></div>
      <div @click="tipTo()"><img src="../assets/img/jr4.png" alt=""></div>
      <div @click="popTo(1)"><img src="../assets/img/jr5.png" alt=""></div>
      <div @click="navTo('http://www.gxjrfw.com/')"><img style="margin-top:3vw" src="../assets/img/jr6.jpg" alt=""></div>
      <div @click="navTo('https://xyd.fgw.gxzf.gov.cn/')"><img src="../assets/img/jr7.png" alt=""></div>
    </div>
    <van-popup v-model:show="show">
      <img class="w8" :src="urlPath" alt="">
    </van-popup>
  </div>
</template>
<script>
import {useRouter} from 'vue-router';
import { Toast,Popup } from 'vant';
import { ref} from 'vue';
import img_url from '@/assets/img/dhxcx.jpg';
import img_url2 from '@/assets/img/yyxcx.jpg';
  export default {
   setup() {
     let router = useRouter();
     const show=ref(false);
     const urlPath = ref(img_url);
     const navTo = (url) => {
        window.location.href = url;
    };
    const tipTo = () => {
        Toast('即将推出，敬请留意')
    };
    const popTo = (i) => {
      if(i===2) {
        urlPath.value = img_url2;
        show.value = true;
      }else {
        urlPath.value = img_url;
        show.value = true;
      }
        
    };
    return {
      show,
      urlPath,
      navTo,
      tipTo,
      popTo
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.fsc {
  padding-top: 12.5vw;
  .fsc-content {
    margin: 6vw;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    div {
      display: inline-block;
      margin-bottom: 2vw;
      font-size: 0;
      width: 42vw;
      height: 12vw;
      margin-right: 2vw;
      background-color: #fff;
      img {
        width: 100%;
      }
      
    }
  }
  .w8 {
    width: 80vw;
    height: auto;
  }
}
</style>
